import { AxiosManager } from 'app/services/axiosManager';
import BaseApi from 'app/services/common/base-api';

class QuestionnaireService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/questionnaire');
  }

  async updateQuestionnaire({ questionnaireId, schema, callback }) {
    try {
      const {
        data: { data },
      } = await this.axios.put(`/${questionnaireId}`, schema);

      callback();

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async createQuestionnaire({ schema, callback, mode }) {
    try {
      const {
        data: { data },
      } = await this.axios.post(``, schema, mode && { headers: { 'X-Workmode': mode } });

      callback?.();

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getQuestionnaireById(questionnaireId) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`/${questionnaireId}`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async deleteQuestionnaire(questionnaireId) {
    try {
      const {
        data: { data },
      } = await this.axios.delete(`/${questionnaireId}`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getAllQuestionnaires(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get('/', { params: queryParams });

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new QuestionnaireService();
