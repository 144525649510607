const locale = {
  NAME: 'Name',
  TEST: 'Test',
  LIVE: 'Live',
  CANCEL: 'Cancel',
  DUPLICATE: 'Duplicate',
  YES_DELETE: 'Yes delete',
  QUESTIONNAIRE: 'Questionnaire',
  QUESTIONNAIRES: 'Questionnaires',
  SEARCH_BY_NAME: 'Search by name',
  CREATE_QUESTIONNAIRE: 'Create questionnaire',
  DELETE_QUESTIONNAIRE: 'Delete questionnaire',
  DUPLICATE_QUESTIONNAIRE: 'Duplicate questionnaire',
  DELETE_DRAFT_QUESTIONNAIRE: 'Delete draft questionnaire',
  SURVEY_TITLE_IS_REQUIRED_FIELD: 'Survey title is a required filed',
  CONFIRM_QUESTIONNAIRE_DELETE: 'Are you sure you want to delete questionnaire?',
  DUPLICATED_SUCCESSFULLY: 'The questionnaire has been successfully duplicated.',
  SELECT_QUESTIONNAIRE_STATUS: 'Select the status of the duplicate questionnaire',
  CONFIRM_DRAFT_QUESTIONNAIRE_DELETE: 'Are you sure you want to delete draft questionnaire?',

  EMPTY_STATE_DESCRIPTION:
    'No questionnaires created yet. Questionnaires help gather detailed applicant information. Create one to begin.',
};

export default locale;
