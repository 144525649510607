import { useMemo, useState } from 'react';

import Tab from '@mui/material/Tab';
import TabsList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Typography from '@mui/material/Typography';

import { getStyles } from './utils';

const Tabs = ({ defaultValue = '0', tabs = [], onChange, children, variant = 'primary', customSX = {} }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    onChange?.(newValue);
  };

  const { tabClasses, tabsListClasses, sx } = useMemo(() => getStyles(variant), [variant]);
  const labelVariant = variant === 'primary' ? 'button' : 'caption';

  return (
    <TabContext value={value}>
      <TabsList sx={{ ...sx, ...customSX }} className={tabsListClasses} onChange={handleChange}>
        {tabs.map((tab) => (
          <Tab
            icon={tab?.icon}
            key={tab.value}
            className={tabClasses}
            label={
              <Typography variant={labelVariant} color="inherit" fontWeight="600">
                {tab.label}
              </Typography>
            }
            value={tab.value}
            sx={(tab?.error && { border: '1px solid', borderColor: 'red.800' }) || {}}
          />
        ))}
      </TabsList>
      {children}
    </TabContext>
  );
};

export default Tabs;
