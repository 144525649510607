// import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { ComponentCollection, setLicenseKey } from 'survey-core';
import { HTML5Backend } from 'react-dnd-html5-backend';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import settingsConfig from 'app/configs/settingsConfig';
import withAppProviders from './withAppProviders';
import { AuthProvider } from './auth';
import { authSelectors } from './auth/store/userSlice';
import Notification from './shared-components/Notification/Notification';
import { countryListAlpha2 } from './constants/countries';

setLicenseKey(process.env.REACT_APP_SURVEY_LICENSE_KEY);
ComponentCollection.Instance.add({
  name: 'country',
  title: 'Country',
  questionJSON: {
    type: 'dropdown',
    placeholder: 'Select a country...',
    choices: Object.values(countryListAlpha2).map((o) => o),
    // choicesByUrl: {
    //   url: 'https://surveyjs.io/api/CountriesExample',
    // },
  },
});

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

const App = () => {
  const userPermissions = useSelector(authSelectors.selectUserPermissions);
  const userRole = useSelector(authSelectors.currentRole);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);

  return (
    <DndProvider backend={HTML5Backend}>
      <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
        <FuseTheme theme={mainTheme} direction={langDirection}>
          <Notification />
          <AuthProvider>
            <BrowserRouter>
              <FuseAuthorization
                userRole={userRole}
                userPermissions={userPermissions}
                loginRedirectUrl={settingsConfig.loginRedirectUrl}
              >
                <SnackbarProvider
                  maxSnack={5}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  classes={{
                    containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                  }}
                >
                  <FuseLayout layouts={themeLayouts} />
                </SnackbarProvider>
              </FuseAuthorization>
            </BrowserRouter>
          </AuthProvider>
        </FuseTheme>
      </CacheProvider>
    </DndProvider>
  );
};

export default withAppProviders(App)();
