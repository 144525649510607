export const VERIFICATION_STATUS = {
  INIT: 'INIT',
  ON_HOLD: 'ON_HOLD',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  IN_REVIEW: 'IN_REVIEW',
  INCOMPLETE: 'INCOMPLETE',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const APPLICANT_SERVICE_STATUS = {
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
};

export const RESULT_STATUS = {
  SUCCESS: 'SUCCESS',
  DECLINED: 'DECLINED',
};

export const FILTER_PARAMS = {
  APPLICATION: 'appKey',
  PLATFORM: 'platform',
  STATUS: 'status',
  SEARCH: 'search',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  WARNING: 'warning',
  PAGINATION: {
    OFFSET: 'offset',
    LIMIT: 'limit',
  },
};

export const VERIFICATION_ACTION = {
  APPROVE: 'APPROVED',
  DECLINE: 'DECLINE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  DOWNLOAD: 'DOWNLOAD',
  REVIEWER: 'REVIEWER',
  REJECTED: 'REJECTED',
  RESTORE: 'RESTORE',
};

export const SERVICES = {
  DOCUMENT_CAPTURE: 'DOCUMENT_CAPTURE',
  CLEAN_DOCUMENT_CAPTURE: 'CLEAN_DOCUMENT_CAPTURE',
  BARCODE_CAPTURE: 'BARCODE_CAPTURE',
  CREDIT_CARD_CAPTURE: 'CREDIT_CARD_CAPTURE',
  RFID_DOCUMENT_CAPTURE: 'RFID_DOCUMENT_CAPTURE',
  SPECIFIC_DOCUMENT_CAPTURE: 'SPECIFIC_DOCUMENT_CAPTURE',
  SELFIE_CAPTURE: 'SELFIE_CAPTURE',
  FACE_CAPTURE: 'FACE_CAPTURE',
  FACE_MATCH: 'FACE_MATCH',
  FACE_LIVENESS: 'FACE_LIVENESS',
  PASSIVE_LIVENESS: 'PASSIVE_LIVENESS',
  PASSIVE_FACE_MATCH: 'PASSIVE_FACE_MATCH',
  SIGNATURE: 'SIGNATURE',
  OTP_VERIFICATION: 'OTP_VERIFICATION',
  UTILITY_BILL: 'UTILITY_BILL',
  DIIA: 'DIIA',
  AML: 'AML',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  PHONE_VERIFICATION: 'PHONE_VERIFICATION',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  AI_ASSISTANT_DOCUMENT: 'AI_ASSISTANT_DOCUMENT',
};

export const BG_CHECKS_SERVICES = [SERVICES.AML, SERVICES.FACE_MATCH];

export const SERVICE_STATUS = {
  COMPLETED: 'completed',
  NOT_COMPLETE: 'notComplete',
};

export const TRANSLATION_KEY = 'verifications';

export const DATE_FORMAT = 'DD.MM.YYYY';

export const VERIFICATION_MODULE_KEY = 'verifications';
export const COMMENTS_MODULE_KEY = 'comments';
export const APPLICANT_MODULE_KEY = 'applicants';
export const SERVICES_MODULE_KEY = 'verification-services';
export const HISTORY_APPLICANT_MODULE_KEY = 'history-applicant';
export const DOCUMENT_CHECK_MODULE_KEY = 'document-check-fields';
export const AML_MODULE_KEY = 'aml';

export const PATHS = {
  APPLICANTS: '/verifications/applicants',
  VERIFICATIONS: '/verifications/transactions',
  VERIFICATION_DETAILS: '/verifications/transaction/info/:transactionId',
  APPLICANTS_DETAILS: '/applicant-details/:applicantId',
  VERIFICATION_HISTORY: '/verifications/transaction/:transactionId/history',
  APPLICANTS_HISTORY: '/applicant-details/:applicantId/history',
};

export const MIN_TRUST_LEVEL = 0;
export const MAX_TRUST_LEVEL = 100;

export const TYPE_IMAGE = {
  DOCUMENT_CAPTURE: 'documentCaptureImages',
  FACE_MATCH: 'faceMathImages',
  PASSIVE_FACE_MATCH: 'passiveFaceMatchImages',
  PASSIVE_LIVENESS: 'passiveLiveness',
  FACE_CAPTURE: 'faceCaptureImage',
  SELFIE_CAPTURE: 'selfieCaptureImage',
  UTILITY_BILL: 'utilityBillImage',
  SIGNATURE: 'signatureImage',
  SPECIAL_DOCUMENT: 'specialDocumentImage',
  SPECIAL_DOCUMENT_CAPTURE: 'specificDocumentCaptureImage',
};

export const TYPE_MODULE = {
  APPLICANT: 'applicant',
  VERIFICATION: 'verification',
};

export const hiddenAssignButtonStatuses = [VERIFICATION_STATUS.INIT, VERIFICATION_STATUS.IN_PROGRESS];

export const ONGOING_MONITORING_PERIODICITY = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  HALF_EARLY: 'HALF_EARLY',
  YEARLY: 'YEARLY',
};

export const AML_CHECK_STATUSES = {
  INIT: 'INIT',
  DONE: 'DONE',
  FAIL: 'FAIL',
  PROCESS: 'PROCESS',
};

export const ReviewWarning = {
  AML_CANNOT_STARTED: 'AML_CANNOT_STARTED',
  AML_POSITIVE_CASES: 'AML_POSITIVE_CASES',
  AML_RESULT_UPDATED: 'AML_RESULT_UPDATED',
  APPLICANT_DUPLICATE_DOCUMENT: 'APPLICANT_DUPLICATE_DOCUMENT',
  APPLICANT_DUPLICATE_EMAIL: 'APPLICANT_DUPLICATE_EMAIL',
  APPLICANT_DUPLICATE_PHONE: 'APPLICANT_DUPLICATE_PHONE',
  EXPIRED_DOCUMENT: 'EXPIRED_DOCUMENT',
  ADDITIONAL_DOCUMENT_STEP_ERROR: 'ADDITIONAL_DOCUMENT_STEP_ERROR',
};

export const CONSIDERED_ACTION = {
  APPROVE: 'approve',
  DECLINED: 'decline',
};

export const CHANGE_SERVICE_BUTTON_TYPES = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
};
