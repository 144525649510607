import { createAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { BaseEntityStoreBuilder } from 'app/store/helpers';
import questionnaireService from './questionnaireService';

export const QUESTIONNAIRES_MODULE_KEY = 'questionnaire';

const storeBuilder = new BaseEntityStoreBuilder(QUESTIONNAIRES_MODULE_KEY, questionnaireService);

const entityStore = (state) => state[QUESTIONNAIRES_MODULE_KEY];

export const actions = {
  deleteQuestionnaire: storeBuilder.createAction('deleteQuestionnaire'),
  getAllQuestionnaires: storeBuilder.createAction('getAllQuestionnaires'),
  getQuestionnaireById: createAsyncThunk(
    `${QUESTIONNAIRES_MODULE_KEY}/getQuestionnaireById`,
    async ({ questionnaireId, setItem }) => {
      const response = await questionnaireService.getQuestionnaireById(questionnaireId);
      const { name, description, content, id } = { ...response };

      if (setItem) {
        setItem({ id, name, description, ...content });
      }
      return response;
    }
  ),
  setQuestionnaireIndex: createAction(`${QUESTIONNAIRES_MODULE_KEY}/setQuestionnaireIndex`),
  createQuestionnaire: storeBuilder.createAction('createQuestionnaire'),
  updateQuestionnaire: storeBuilder.createAction('updateQuestionnaire'),
};

export const selectors = {
  ...storeBuilder.selectors,
  selectQuestionnaire: createSelector(entityStore, (state) => state.questionnaire),
  selectQuestionnaireIndex: createSelector(entityStore, (state) => state.questionnaireIndex),
};

const questionnaireSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.getQuestionnaireById.fulfilled, (state, action) => {
      state.questionnaire = action.payload;
    })
    .addCase(actions.setQuestionnaireIndex, (state, { payload }) => {
      state.questionnaireIndex = { index: payload };
    })

    .addCase(actions.getAllQuestionnaires.fulfilled, (state, { payload, meta }) => {
      const { arg } = meta;
      const { offset } = { ...arg };

      if (!offset) {
        return storeBuilder.adapter.setAll(state, payload);
      }
      return storeBuilder.adapter.addMany(state, payload);
    })
    .addCase(actions.deleteQuestionnaire.fulfilled, (state, { meta }) => {
      state.total -= 1;

      return storeBuilder.adapter.removeOne(state, meta.arg);
    })
    .addCase(actions.createQuestionnaire.fulfilled, (state, action) => {
      if (action.meta.arg?.isDuplicate) {
        return storeBuilder.adapter.addOne(state, action);
      }
    })
);

export default questionnaireSlice.reducer;
